import React from 'react'
import Footer from '../components/Footer'
import Nav from '../components/Nav'

import Hero from '../components/Hero'
import MyMap from '../modules/map'

import { graphql, useStaticQuery } from 'gatsby';

export default function Contact() {

    const data = useStaticQuery(
        graphql`
        query {
            file(relativePath: {eq: "Heros/contact.jpg"}) {
                childImageSharp {
                  gatsbyImageData(quality: 100, placeholder: DOMINANT_COLOR)
                }
              }
          }
        `)

    const heroImage = data.file.childImageSharp.gatsbyImageData;
    

    return (
        <div>
            <Nav isActive="Contact"/>
            <Hero title="Contact Us" image={heroImage}/>
            <section className="bg-sphinx-background-white">
                <div className="container mx-auto px-6 py-8 lg:max-w-6xl">
                    <div className="space-y-5">
                        <h1 className="text-4xl font-light font-montserrat text-sphinx-accent">Get In Touch</h1>
                        <p className="text-base font-sanspro text-gray-600 tracking-wide leading-loose max-w-xl">For more information about Sphinx Residential, Sphinx Development, SDC Construction, or how to apply for space in any of our available homes, please contact us through our cooperate phone number or by filling out a contact form below. </p>
                    </div>

                    <div className="mt-8">
                        <div className="w-full h-48 bg-sphinx-background-dark">
                            <MyMap properties={[]} contact={true}/>
                        </div>
                        <div className="pt-6">
                            <h4 className="text-xl font-montserrat font-medium text-sphinx-accent">Sphinx Residential Office</h4>
                            <p className="text-base font-sanspro text-gray-600 tracking-wide leading-loose">
                                3030 LBJ Freeway Suite 1350
                                <br/>
                                Dallas, Texas 75234
                                <br/>
                                <span className="text-sphinx-accent">(123) 456-7890</span>
                            </p>
                        </div>
                        <div className="border-t-2 mt-6 pt-3">
                            <h4 className="text-xl font-montserrat font-medium text-sphinx-accent">Interested In Joining Our Team?</h4>
                            <p className="text-base font-sanspro text-gray-600 tracking-wide leading-loose" >Check out our open positions via Indeed Jobs! <a className="text-blue-600 underline" href="https://www.indeed.com/cmp/Sphinx-Residential,-LLC/jobs">Visit Indeed</a> </p>
                            
                        </div>
                    </div>
                </div>
            </section>

            <section className="bg-sphinx-background-blue">
                <div className="container mx-auto px-6 py-14 lg:max-w-6xl space-y-5">
                    <div className="space-y-3 lg:text-center">
                        <h1 className="text-4xl font-light font-montserrat text-sphinx-accent">Contact Form</h1>
                        <p className="text-base font-sanspro text-gray-600 tracking-wide leading-loose max-w-xl lg:mx-auto">To ensure quality communication between both parties, please fill out each information box, if applicable, and provide a brief summary of what you would like to discuss. We will reach out to you shortly.</p>
                    </div>
                    <form method="post" netlify-honeypot="bot-field" data-netlify="true" name="contact">
                        <input type="hidden" name="bot-field" />
                        <input type="hidden" name="form-name" value="contact" />
                        <div className="space-y-3">
                            <h6 className="text-base font-light font-montserrat text-sphinx-accent">Your Contact Info</h6>
                            <div className="lg:flex lg:flex-nowrap space-y-3 lg:space-x-4 lg:space-y-0">
                                <input type="text" name="name" placeholder="Your Name*" className="contact-input" required></input>
                                <input type="text" name="company" placeholder="Company" className="contact-input"></input>
                            </div>
                        </div>
                        <div className="space-y-3 py-3">
                            <div className="lg:flex lg:flex-nowrap space-y-3 lg:space-x-4 lg:space-y-0">
                                <input type="email" name="email" placeholder="Your Email" className="contact-input"></input>
                                <input type="tel" name="number" placeholder="Your Number (###-###-###)*" className="contact-input" required pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" maxlength="12"></input>
                            </div>
                        </div>
                        <div className="space-y-3 mt-5">
                            <h6 className="text-base font-light font-montserrat text-sphinx-accent">How can we help?</h6>
                            <textarea type="text" name="message" placeholder="Your Message" className="contact-input h-40"></textarea >
                        </div>
                        <div className="pt-5 lg:w-full lg:text-center">
                        <button className="bg-sphinx-accent h-12 w-36 shadow-md text-white m-auto text-sm font-montserrat" type="submit">Send Message</button>
                    </div>
                    </form>
                </div>
            </section>
            <Footer/>
        </div>
    )
}
